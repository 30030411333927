<template>
    <div>
        <b-card>
            <div class="row align-items-center">
                <div class="col-md-4">
                    <h1>Cluster Profile Card</h1>
                </div>
                <div class="col-md-8">
                    <Counts :data="studentCounts" header="" />
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-4">
                    <v-select :options="selectOptions" placeholder="Select an option" label="label" class="w-100" />
                </div>
                <div class="col-md-8">
                    <div class="row align-items-center justify-content-end">
                        <div class="col-md-4 border text-center rounded m-2 p-2">
                            <p>Visa</p>
                        </div>
                        <div class="col-md-4 border text-center rounded m-2 p-2">
                            <p>Packages</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <p class="text-center">Year 2024</p>
                    <div class="d-flex align-items-center">
                        <div class="arrow-container">
                            <button class="arrow-button" @click="previousYear">
                                &larr;
                            </button>
                        </div>

                        <div class="progress-bar-container position-relative mx-3">
                            <div class="progress-bar-section" :class="{ 'bg-danger': count > 50 }" style="width: 33.33%;">
                                1-50
                            </div>

                            <div class="progress-bar-section" :class="{ 'orange': count > 100 }" style="width: 33.33%;">
                                51-100
                            </div>

                            <div class="progress-bar-section" :class="{ 'green': count > 150 }" style="width: 33.34%;">
                                101-150
                            </div>
                        </div>

                        <div class="arrow-container">
                            <button class="arrow-button" @click="nextYear">
                                &rarr;
                            </button>
                        </div>
                    </div>


                </div>
                <div class="col-md-6">
                    <h1>9999</h1>
                </div>
            </div>
            <div class="row align-items-center mt-2">
                <div class="col-md-6">
                    <p class="text-center">Sep 2024</p>
                    <div class="d-flex align-items-center">
                        <div class="arrow-container">
                            <button class="arrow-button" @click="previousYear">
                                &larr;
                            </button>
                        </div>

                        <div class="progress-bar-container position-relative mx-3">
                            <div class="progress-bar-section" :class="{ 'bg-danger': count > 50 }" style="width: 33.33%;">
                                1-50
                            </div>

                            <div class="progress-bar-section" :class="{ 'orange': count > 100 }" style="width: 33.33%;">
                                51-100
                            </div>

                            <div class="progress-bar-section" :class="{ 'green': count > 150 }" style="width: 33.34%;">
                                101-150
                            </div>
                        </div>

                        <div class="arrow-container">
                            <button class="arrow-button" @click="nextYear">
                                &rarr;
                            </button>
                        </div>
                    </div>


                </div>
                <div class="col-md-6">
                    <h1>9999</h1>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h1>Select Franchise</h1>
                    <v-select :options="franchiseOpt" multiple placeholder="Select an option" label="label" class="w-100 mt-1" />
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import Counts from '@/views/components/StaffHomeComponents/Counts.vue';
import { BCard } from 'bootstrap-vue';
import vSelect from "vue-select";

export default {
    components: {
        BCard,
        Counts,
        vSelect,
    },
    data() {
        return {
            studentCounts: [
                { label: 'TS', value: 99 },
                { label: 'CS', value: 99 },
                { label: 'VS', value: 99 },
                { label: 'PS', value: 99 },
            ],
            selectOptions: [
                { label: 'Manager 1', value: 'option1' },
                { label: 'Manager 2', value: 'option2' },
                { label: 'Manager 3', value: 'option3' },
                { label: 'Manager 4', value: 'option4' },
            ],
            franchiseOpt: [
                { label: 'Franchise 1', value: 'option1' },
                { label: 'Franchise 2', value: 'option2' },
                { label: 'Franchise 3', value: 'option3' },
                { label: 'Franchise 4', value: 'option4' },
            ],
            currentYear: 2024,
            count: 51,
        }
    },
    methods: {
        previousYear() {
            this.currentYear--;
            // Logic to update progress bar values based on the year can go here
        },
        nextYear() {
            this.currentYear++;
            // Logic to update progress bar values based on the year can go here
        },
    },
}
</script>
<style>
.progress-bar-container {
    display: flex;
    height: 100px;
    width: 500px;
    border: 1px solid #ccc;
    overflow: hidden;
}

/* Progress bar sections with colors */
.progress-bar-section {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
    background-color: white;
    /* Default to white */
    border-right: 1px solid #ddd;
    /* Optional: to visually separate sections */
}

/* Active state based on conditions */
.red {
    background-color: red !important;
}

.orange {
    background-color: orange !important;
}

.green {
    background-color: green !important;
}

/* Arrow button styles */
.arrow-container {
    display: flex;
    align-items: center;
}

.arrow-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    transition: transform 0.2s;
}

.arrow-button:hover {
    transform: scale(1.1);
}
</style>